<template>
  <div class="develop-container">
    <div class="line">
      <label>nodeName:</label>
      <input type="text" v-model="nodeName"/>
    </div>
    <div class="line">
      <label>productId:</label>
      <input type="text" v-model="productId"/>
    </div>
    <div class="line">
      <label>num:</label>
      <input type="text" v-model="num"/>
    </div>
    <div class="line">
      <label>工单号:</label>
      <input type="text" v-model="workId"/>
    </div>
<!--    <div class="line">-->
<!--      <label>remark:</label>-->
<!--      <textarea v-model="remark" style="width: 400px;height:200px;"></textarea>-->
<!--    </div>-->
    <div class="line">
      <button @click="onCreate">创建兑换码</button>
    </div>
    <div class="line">
      <label>message:</label>
      <pre>{{ message }}</pre>
    </div>
    <div class="line">
      <label>exchangeCodes:</label>
      <pre>{{ JSON.stringify(exchangeCodes, null, 4) }}</pre>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request'

export default {
  data() {
    return {
      nodeName: 'staging-cn',
      accountId: 'paas_owned',
      tenantId: 'paas_owned',
      productId: 1015003,
      num: 1,
      // remark: '20211209_SALES-1677_申请1个prod-us环境12个月基础版（Basic）的云服务兑换码',
      workId: '',

      exchangeCodes: null,
      message: '',
    }
  },
  created() {

  },
  methods: {
    async onCreate() {
      const resp = await this.createExchangeCode();
      this.message = resp.message;
      if (resp.code == 0) {
        this.exchangeCodes = resp.data;
      }
    },
    async createExchangeCode() {
      if (!this.workId || this.workId.trim() === '') {
        alert('工单号不能为空！');
        return;
      }
      const requestConfig = {
        url: '/aiDemo/invokePaasApi',
        method: 'post',
        params: {
          accountId: this.accountId,
          tenantId: this.tenantId,
          nodeName: this.nodeName,
          path: `/work/productExchangeCode/create`,
          method: 'POST'
        },
        data: {
          "secret": "Addx1234",
          "productId": this.productId,
          "expireSeconds": 31536000,
          "num": this.num,
          "remark": this.workId.trim()
        }
      }
      return await request(requestConfig)
    },
  }
}
</script>
<style lang="scss" scoped>
.line {
  padding: 3px 10px;
  //pre {
  //  border-width: 1px;
  //  border-style: solid;
  //}
  label {
    width: 100px;
  }
}
</style>
